/* eslint-disable no-unused-vars */
// eslint-disable-next-line react-hooks/exhaustive-deps
/* eslint-disable */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { useState } from "react";
import { BASE_URL, BUS_TICKET_SALES_FILTER_BY } from "../../constant/constants";
import { getTicketpassengers, getAllTickets } from "../../dataSlice/dataSlice";

const TicketList = () => {
  const dispatch = useDispatch();
  const [datas, setData] = useState([]);
  const [filterData, setFilterData] = useState({});

  const user_id = localStorage.getItem("user_id");
  const buses = useSelector((state) => state.data?.bus);
  const passengers = useSelector((state) => state.data?.ticketpassengers);
  const filteredPassengers = passengers.filter(
    (passenger) => passenger.member == user_id
  );

  const ticketDetails = useSelector((state) => state.data?.alltickets);

  // eslint-disable-next-line
  useEffect(() => {
    dispatch(getTicketpassengers());
    const user_id = localStorage.getItem("user_id");
  }, [dispatch, user_id]);

  useEffect(() => {
    dispatch(getAllTickets(filterData));
  }, [filterData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Here, you can handle the form submission logic
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  // Function to generate time options with 30-minute intervals
  const generateTimeOptions = () => {
    const timeOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        const period = hour >= 12 ? "PM" : "AM";
        const formattedMinute = minute === 0 ? "00" : minute;
        const timeLabel = `${formattedHour}:${formattedMinute} ${period}`;
        const timeValue = `${hour
          .toString()
          .padStart(2, "0")}:${formattedMinute}`;
        timeOptions.push({ label: timeLabel, value: timeValue });
      }
    }
    return timeOptions;
  };
  const timeOptions = generateTimeOptions();

  return (
    <div className="mx-3">
      <div className="container mt-4">
        <form onSubmit={handleSubmit}>
          <div>
            <div className="row mx-0">
              <div className="form-group col-md-2">
                <label>Date From:</label>
                <input
                  type="date"
                  className="form-control"
                  name="date_after"
                  value={filterData.date_after}
                  onChange={handleChange}
                />
              </div>{" "}
              <div className="form-group col-md-2">
                <label>Date To:</label>
                <input
                  type="date"
                  className="form-control"
                  name="date_before"
                  value={filterData.date_before}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-2">
                <label>Bus:</label>
                <select
                  className="form-control"
                  name="bus"
                  value={filterData.bus || filterData?.bus}
                  onChange={handleChange}
                >
                  <option value="">Select bus</option>{" "}
                  <option value="">All</option>{" "}
                  {/* Default "Select bus" option */}
                  {buses?.map((bus) => (
                    <option key={bus?.id} value={bus?.id}>
                      {bus?.name}
                    </option>
                  ))}
                </select>
              </div>{" "}
              <div className="form-group col-md-2">
                <label>Passenger:</label>
                <select
                  className="form-control"
                  name="passenger"
                  value={filterData.passenger || filterData?.passenger}
                  onChange={handleChange}
                >
                  <option value="">Select Passenger</option>{" "}
                  <option value="">All</option>{" "}
                  {/* Default "Select bus" option */}
                  {filteredPassengers?.map((passenger) => (
                    <option key={passenger.id} value={passenger.id}>
                      {passenger.first_name} {passenger.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-2">
                <label>Time:</label>
                <select
                  className="form-control"
                  name="time"
                  value={filterData.time || "7:00 AM"}
                  onChange={handleChange}
                >
                  <option value="">Select time</option>{" "}
                  <option value="">All</option>{" "}
                  {/* Default "Select time" option */}
                  {timeOptions.map((timeOption, index) => (
                    <option
                      key={index}
                      value={timeOption.label}
                      disabled={timeOption.label !== "7:00 AM"}
                      style={{
                        fontWeight: timeOption.label == "7:00 AM" ? 800 : 500,
                        color: timeOption.label == "7:00 AM" ? "green" : "",
                      }}
                    >
                      {timeOption.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className='col-md-2' style={{ paddingTop: "20px" }}>
                <button
                  type='submit'
                  onClick={handleGetAllTicket}
                  className='btn btn-primary'>
                  Submit
                </button>
              </div> */}
            </div>
          </div>
        </form>
      </div>
      <h3 className="text-center mt-5">
        All Ticket List ({ticketDetails?.length})
      </h3>
      <div
        className="table-responsive rounded"
        style={{ height: "500px", backgroundColor: "white" }}
      >
        <table className="table table-striped">
          <thead
            style={{ position: "sticky", top: 0 }}
            className="bg-dark text-white"
          >
            <tr style={{ height: "2rem", whiteSpace: "nowrap" }}>
              <th scope="col">SL.</th>
              <th scope="col">Date </th>
              <th scope="col">Bus</th>
              <th scope="col">Time</th>
              <th scope="col">Passenger</th>
              <th scope="col">Seat</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {ticketDetails?.map((ticketDetail, key) => (
              <tr
                style={{ height: "2rem", whiteSpace: "nowrap" }}
                // key={member?.id}
              >
                <th>{key + 1}</th>
                <th>{ticketDetail?.date}</th>
                <th>{`${ticketDetail?.bus?.name}  (${ticketDetail?.bus?.tour_type})`}</th>
                <th>{ticketDetail?.booking_time}</th>
                <th>
                  {ticketDetail?.passenger?.first_name || ""}{" "}
                  {ticketDetail?.passenger?.last_name || ""}
                </th>
                <th>{ticketDetail?.seat_no}</th>
                <th
                  style={{
                    color:
                      ticketDetail?.status == "cancelled" ? "red" : "green",
                  }}
                >
                  {ticketDetail?.status}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TicketList;
