import React from "react";
import { Route, Redirect } from "react-router-dom";

// PrivateRoute component to restrict access to authenticated users only
const PrivateRoute = ({ component: Component, ...rest }) => {
  // Check if the user is authenticated by checking for the presence of jwt_access_token
  const isAuthenticated = !!localStorage.getItem("jwt_access_token");

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          // If authenticated, render the requested component
          <Component {...props} />
        ) : (
          // If not authenticated, redirect to the login page
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }, // Store the original path in state
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
