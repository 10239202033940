import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CREATE_COLLECTION,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_LEVELS,
  GET_ALL_BUSES,
  GET_MEMBERS_BY_LEVEL_NAME,
  GET_MEMBER_BY_ID,
  GET_MENUS_ALL_NESTED,
  GET_SITESETTINGS,
  GET_GIFT_AMOUNTS,
  UPDATE_MEMBER,
  GET_TICKET_DEPARTMENTS,
  GET_SUPPORT_PRIORITYS,
  CREATE_TICKET_DETAIL,
  CREATE_TICKET,
  GET_TICKET_BY_ALL_USER_ID,
  GET_TICKET_DETAILS_BY_ID,
  CREATE_WITHDRAW,
  GET_LEVEL_ACHIEVEMENT,
  GET_USER_TOTAL_AMOUNT,
  GET_BUS_INFO_DATE_TIME,
  SAVE_BUS_TICKET_BOOKING,
  GET_PASSENGERS_FOR_TICKET_WITHOUT_PAGINATION,
  BUS_TICKET_SALES_FILTER_WITHOUT_PG,
  GET_BUS_INFO_DETAIL_BY_BUS_ID,
  BOOKING_COMMISSION,
  CHECK_BOOKING_AVAILITY,
} from "../constant/constants";

//buildformdata
const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data === null ? "" : data;

    formData.append(parentKey, value);
  }
};

//convertJsonToFormData
const jsonToFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);
  return formData;
};

export const getAllmenus = () => (dispatch) => {
  fetch(GET_MENUS_ALL_NESTED)
    .then((res) => res.json())
    .then((data) => dispatch(setAllMenus(data.menus)));
};
export const getAllsupportPrioritys = () => (dispatch) => {
  fetch(GET_SUPPORT_PRIORITYS)
    .then((res) => res.json())
    .then((data) => dispatch(setAllSupportPrioritys(data.ticket_priorities)));
};
export const getAllsupportDepartments = () => (dispatch) => {
  fetch(GET_TICKET_DEPARTMENTS)
    .then((res) => res.json())
    .then((data) =>
      dispatch(setAllSupportDepartments(data.ticket_departments))
    );
};
export const getTicketpassengers = () => (dispatch) => {
  const authTOKEN = {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("jwt_access_token"),
    },
  };
  fetch(GET_PASSENGERS_FOR_TICKET_WITHOUT_PAGINATION, authTOKEN)
    .then((response) => response.json())
    .then((data) => dispatch(setTicketpassengers(data.passenger)))
    .catch(() => {});
};
export const getAllgifts = () => (dispatch) => {
  fetch(GET_GIFT_AMOUNTS)
    .then((res) => res.json())
    .then((data) => dispatch(setAllGifts(data.gifts)));
};
export const getAlllevels = () => (dispatch) => {
  fetch(GET_LEVELS)
    .then((res) => res.json())
    .then((data) => dispatch(setAllLevels(data.levels)));
};
export const getAlllevelAchievements = () => (dispatch) => {
  fetch(GET_LEVEL_ACHIEVEMENT)
    .then((res) => res.json())
    .then((data) => dispatch(setAllLevelAchievements(data.levels)));
};
export const getAllMembersByLevel = (level) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  fetch(`${GET_MEMBERS_BY_LEVEL_NAME}${user_id}/${level}`)
    .then((res) => res.json())
    .then((data) => dispatch(setAllMembersByLevel(data.final_level)));
};
export const getAllimages = (id) => (dispatch) => {
  fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
    .then((res) => res.json())
    .then((data) => dispatch(setAllImages(data.content_images)));
};

export const getAllTickets = (filterData) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  const authTOKEN = {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("jwt_access_token"),
    },
  };
  fetch(
    `${BUS_TICKET_SALES_FILTER_WITHOUT_PG}?bus=${
      filterData.bus || ""
    }&passenger=${filterData.passenger || ""}&member=${
      user_id || ""
    }&booking_time=${filterData.time || ""}&date_after=${
      filterData.date_after || ""
    }&date_before=${filterData.date_before || ""}`,
    authTOKEN
  )
    .then((res) => res.json())
    .then((data) => dispatch(setAllTickets(data.bus_bookings)));
};
export const getBookinSummary = (filterData) => (dispatch) => {
  const user_id = localStorage.getItem("user_id");

  const authTOKEN = {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("jwt_access_token"),
    },
  };
  fetch(
    `${BOOKING_COMMISSION}?member_id=${user_id || ""}&date_after=${
      filterData.date_after || ""
    }&date_before=${filterData.date_before || ""}&bus=${filterData.bus || ""}`,
    authTOKEN
  )
    .then((res) => res.json())
    .then((data) => {
      dispatch(setBookinSummary(data.bus_bookings));
    });
};

export const getMember = (id) => (dispatch) => {
  fetch(`${GET_MEMBER_BY_ID}${id}`)
    .then((res) => res.json())
    .then((data) => dispatch(setMember(data)));
};
export const getBus = () => (dispatch) => {
  fetch(`${GET_ALL_BUSES}`)
    .then((res) => res.json())
    .then((data) => dispatch(setBus(data?.bus)));
};
export const getBusInfo = (busSearchData) => (dispatch) => {
  fetch(
    `${GET_BUS_INFO_DATE_TIME}/${busSearchData?.bus}/${busSearchData?.date}/${busSearchData?.time}`
  )
    .then((res) => res.json())
    .then((data) => dispatch(setBusInfo(data)));
};

export const getMemberAmount = () => (dispatch) => {
  const user_id = localStorage.getItem("user_id");
  // dispatch(setMemberAmount(data?.member_account_logs))
  // fetch(`${GET_USER_TOTAL_AMOUNT}`)
  fetch(`${GET_USER_TOTAL_AMOUNT}${user_id}`)
    .then((res) => res.json())
    .then((data) => dispatch(setMemberAmount(data)));
};
export const getSupportTicketDetail = (id) => (dispatch) => {
  const authTOKEN = {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem("jwt_access_token"),
    },
  };
  fetch(`${GET_TICKET_DETAILS_BY_ID}${id}`, authTOKEN)
    .then((res) => res.json())
    .then((data) => dispatch(setSupportTicketDetail(data.ticket_details)));
};
export const getTickets = (id) => (dispatch) => {
  fetch(`${GET_TICKET_BY_ALL_USER_ID}${id}`)
    .then((res) => res.json())
    .then((data) => dispatch(setTicket(data.tickets)));
};
export const getAllcontents = (id) => (dispatch) => {
  fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
    .then((res) => res.json())
    .then((data) => dispatch(setAllContents(data.menu_contents)));
};
export const getSiteSetting = () => (dispatch) => {
  fetch(GET_SITESETTINGS)
    .then((res) => res.json())
    .then((data) => dispatch(setSiteSetting(data.general_settings)));
};

export const saveMember = createAsyncThunk(
  "amsProjectManagement/data/saveMember",
  async (memberData, { dispatch, getState }) => {
    const memberDataToFormData = jsonToFormData(memberData);
    const authTOKEN = {
      headers: {
        // "Content-type": "multipart/form-data",
        // Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const response = await axios.post(
      `${CREATE_COLLECTION}`,
      memberDataToFormData,
      authTOKEN
    );
    return response;
  }
);

export const saveTicketBooking = createAsyncThunk(
  "amsProjectManagement/data/saveTicketBooking",
  async (ticketBookingData, { dispatch, getState }) => {
    const ticketBookingDataToFormData = jsonToFormData(ticketBookingData);
    const authTOKEN = {
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const response = await axios.post(
      `${SAVE_BUS_TICKET_BOOKING}`,
      ticketBookingDataToFormData,
      authTOKEN
    );
    return response;
  }
);
export const checkBookingAvailability = createAsyncThunk(
  "amsProjectManagement/data/checkBookingAvailability",
  async (checkData, { dispatch, getState }) => {
    const authTOKEN = {
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const response = await axios.post(
      `${CHECK_BOOKING_AVAILITY}`,
      checkData,
      authTOKEN
    );
    return response;
  }
);
export const saveTicketDetail = createAsyncThunk(
  "amsProjectManagement/data/saveTicketDetail",
  async (TicketDetailData, { dispatch, getState }) => {
    const TicketDetailDataToFormData = jsonToFormData(TicketDetailData);
    const authTOKEN = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const response = await axios.post(
      `${CREATE_TICKET_DETAIL}`,
      TicketDetailDataToFormData,
      authTOKEN
    );
    return response;
  }
);
export const saveTicket = createAsyncThunk(
  "amsProjectManagement/data/saveTicket",
  async (ticketData, { dispatch, getState }) => {
    const ticketDetailDataToFormData = jsonToFormData(ticketData);
    const authTOKEN = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const response = await axios.post(
      `${CREATE_TICKET}`,
      ticketDetailDataToFormData,
      authTOKEN
    );
    return response;
  }
);

export const saveWithdraw = createAsyncThunk(
  "amsProjectManagement/data/saveWithdraw",
  async (withdrawData, { dispatch, getState }) => {
    const authTOKEN = {
      headers: {
        "Content-type": "application/json",
        Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const response = await axios.post(
      `${CREATE_WITHDRAW}`,
      withdrawData,
      authTOKEN
    );
    return response;
  }
);
export const updateMember = createAsyncThunk(
  "amsProjectManagement/data/updateMember",
  async (memberData, { dispatch, getState }) => {
    const memberDataToFormData = jsonToFormData(memberData);
    const authTOKEN = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const user_id = localStorage.getItem("user_id");
    try {
      const response = await axios.put(
        `${UPDATE_MEMBER}${user_id}`,
        memberDataToFormData,
        authTOKEN
      );
      return response;
    } catch (err) {}
  }
);
// export const getBusInfoDetails = createAsyncThunk(
//   "amsProjectManagement/data/getBusInfoDetails",
//   async (busId, { dispatch, getState }) => {
//     const authTOKEN = {
//       headers: {
//         "Content-type": "application/json",
//         Authorization: localStorage.getItem("jwt_access_token"),
//       },
//     };
//     try {
//       const response = await axios.get(
//         `${GET_BUS_INFO_DETAIL_BY_BUS_ID}${busId}`,
//         authTOKEN
//       );
//       return response;
//     } catch (err) {}
//   }
// );

const dataSlice = createSlice({
  name: "amsProjectManagement/data",
  initialState: {
    menus: [],
    supportPrioritys: [],
    ticketpassengers: [],
    supportDepartments: [],
    gifts: [],
    levels: [],
    levelAchievements: [],
    membersByLevel: [],
    member: [],
    bus: [],
    businfo: [],
    memberAmount: [],
    supportTicketDetail: [],
    ticket: [],
    images: [],
    contents: [],
    siteSetting: [],
    alltickets: [],
    allBooking: [],
  },
  reducers: {
    setAllMenus: (state, action) => {
      state.menus = action.payload ? action.payload : [];
    },
    setAllSupportPrioritys: (state, action) => {
      state.supportPrioritys = action.payload ? action.payload : [];
    },
    setAllSupportDepartments: (state, action) => {
      state.supportDepartments = action.payload ? action.payload : [];
    },
    setAllGifts: (state, action) => {
      state.gifts = action.payload ? action.payload : [];
    },
    setAllMembersByLevel: (state, action) => {
      state.membersByLevel = action.payload ? action.payload : [];
    },
    setMember: (state, action) => {
      state.member = action.payload ? action.payload : [];
    },

    setBus: (state, action) => {
      state.bus = action.payload ? action.payload : [];
    },
    setBusInfo: (state, action) => {
      state.busInfo = action.payload ? action.payload : [];
    },
    setTicketpassengers: (state, action) => {
      state.ticketpassengers = action.payload ? action.payload : [];
    },
    setMemberAmount: (state, action) => {
      state.memberAmount = action.payload ? action.payload : [];
    },
    setSupportTicketDetail: (state, action) => {
      state.supportTicketDetail = action.payload ? action.payload : [];
    },
    setTicket: (state, action) => {
      state.ticket = action.payload ? action.payload : [];
    },
    setAllLevels: (state, action) => {
      state.levels = action.payload ? action.payload : [];
    },
    setAllLevelAchievements: (state, action) => {
      state.levelAchievements = action.payload ? action.payload : [];
    },
    setAllImages: (state, action) => {
      state.images = action.payload ? action.payload : [];
    },
    setAllTickets: (state, action) => {
      state.alltickets = action.payload ? action.payload : [];
    },
    setBookinSummary: (state, action) => {
      state.allBooking = action.payload ? action.payload : [];
    },
    setAllContents: (state, action) => {
      state.contents = action.payload ? action.payload : [];
    },
    setSiteSetting: (state, action) => {
      state.siteSetting = action.payload ? action.payload : [];
    },
  },
  extraReducers: {
    // [saveMember.fulfilled]: (state, action) => action.payload,
  },
});

const {
  setAllMenus,
  setAllTickets,
  setBookinSummary,
  setAllSupportPrioritys,
  setAllSupportDepartments,
  setAllGifts,
  setTicketpassengers,

  setAllLevels,
  setAllLevelAchievements,
  setAllMembersByLevel,
  setMember,
  setBus,
  setBusInfo,
  setMemberAmount,
  setSupportTicketDetail,
  setTicket,
  setSiteSetting,
  setAllImages,
  setAllContents,
} = dataSlice.actions;
export default dataSlice.reducer;
